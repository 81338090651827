class AuthService {
  static getSSOLink() {
    return fetch(`${process.env.REACT_APP_BACK_URL}/authentication/sso/login`)
      .then(res => res.json())
      .then(json => json)
      .catch(err => err);
  }

  static getSSOConnectedUser() {
    return fetch(`${process.env.REACT_APP_BACK_URL}/authentication/sso/user`)
      .then(res => res.json())
      .then(json => json)
      .catch(err => err);
  }
}

export default AuthService;
