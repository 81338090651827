import React, { Suspense, useEffect, useState } from 'react';
import { Badge, Popover, Spin, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import formatTableData from '../../../../_utils/formatTableData';
import ProcessesService from '../../../../_services/processes.service';
import CollaboratorsService from '../../../../_services/collaborators.service';
import ParametersService from '../../../../_services/parameters.service';
import EditableTextCell from '../../../shared/cells/editableTextCell';
import { editCollaboratorsError, editCollaboratorsSuccess } from '../../../../store/actions/collaboratorsAction';
import EditableSelectCell from '../../../shared/cells/editableSelectCell';
import EditableDateCell from '../../../shared/cells/editableDateCell';
import TutorialPopover from '../../../shared/popovers/tutorial.popover';
import './home.scss';
import { useToasts } from '../../../shared/toastr/toast.manager';
import getLongest from '../../../../_utils/getLongest';

const { TabPane } = Tabs;

const GeneralTable = React.lazy(() => import('./_components/generalTable'));
const WarningTable = React.lazy(() => import('./_components/warningTable'));
const ErrorTable = React.lazy(() => import('./_components/errorTable'));

const Home = () => {
  const dispatch = useDispatch();
  const [processes, setProcesses] = useState([{}]);
  const collaborators = useSelector((state: any) => state.collaborators.collaborators);
  const parameters = useSelector((state: any) => state.parameters.parameters);
  const [collabsLists, setCollabsLists] = useState({ general: [], error: [], warning: [] });
  const [numberOfCollabs, setNumberOfCollabs] = useState({ general: 0, error: 0, warning: 0 });
  const { addToastr } = useToasts();

  const handleChange = async (value: any, record: any, key: any) => {
    const copyCollab = collaborators.find((c: any) => c.id === record.id);
    copyCollab.step = record.step;
    if (key !== 'step') {
      const val = value === 'Invalid date' ? null : value;
      copyCollab[key] = val;
    }
    copyCollab.step_id = value.id || copyCollab.step.id;
    try {
      const json = await CollaboratorsService.updateCollaborators([copyCollab]);
      dispatch(editCollaboratorsSuccess(json));
    } catch (err) {
      dispatch(editCollaboratorsError(err));
    }
  };

  const getClassName = (field: string, record: any) => {
    let className = '';
    if (record.warningsFields && record.warningsFields.includes(field)) {
      className += 'editable_cell_warning ';
    }
    if (record.errorsFields && record.errorsFields.includes(field)) {
      className += 'editable_cell_error';
    }
    return className;
  };

  const inputNumberSchema = Yup.object({
    input: Yup.number(),
  });

  const inputEmailSchema = Yup.object({
    input: Yup.string()
      .trim()
      .matches(/^[A-Za-z-]+\.[A-Za-z-]+@atos\.net$/, `Le format de l'email est incorrect.`),
  });
  // eslint-disable-next-line
  const columns = [
    {
      title: 'Matricule',
      dataIndex: 'matricule',
      fixed: 'left',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'matricule')}
          className={getClassName('matricule', record)}
          inputType="number"
          schema={inputNumberSchema}
        />
      ),
    },
    {
      title: 'Étape',
      dataIndex: 'step',
      fixed: 'left',
      align: 'center',
      width: `calc( ${getLongest(processes, 'step', 'Étape').length}ch + 100px)`,
      render: (text: any, record: any, index: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EditableSelectCell
            key={index}
            text={formatTableData(text, 'step')}
            content={processes}
            contentKey="step"
            change={(value: any) => handleChange(value, record, 'step')}
            className={getClassName('step', record)}
            disabled={!!(record.on_hold_date || record.hold_reason)}
          />
        </div>
      ),
    },
    {
      title: 'Dernier mail envoyé',
      dataIndex: 'mailSendDates',
      render: (text: any) => (
        <div className="mailSendDates">
          <span>{formatTableData(text, 'mailSendDates', 'date')}</span>
          <span>{formatTableData(text, 'mailSendDates', 'week')}</span>
        </div>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'last_name',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'last_name')}
          className={getClassName('last_name', record)}
        />
      ),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'first_name')}
          className={getClassName('first_name', record)}
        />
      ),
    },
    {
      title: 'Das',
      dataIndex: 'das',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'das')}
          className={getClassName('das', record)}
        />
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 250,
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'email')}
          className={getClassName('email', record)}
          schema={inputEmailSchema}
        />
      ),
    },
    {
      title: 'Organisation',
      dataIndex: 'organization',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'organization')}
          className={getClassName('organization', record)}
        />
      ),
    },
    {
      title: 'Manager',
      dataIndex: 'manager_full_name_1',
      width: 250,
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'manager_full_name_1')}
          className={getClassName('manager_full_name_1', record)}
        />
      ),
    },
    {
      title: 'Matricule manager',
      dataIndex: 'manager_matricule_1',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'manager_matricule_1')}
          className={getClassName('manager_matricule_1', record)}
          inputType="number"
          schema={inputNumberSchema}
        />
      ),
    },
    {
      title: 'Email manager',
      dataIndex: 'manager_email_1',
      width: 250,
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'manager_email_1')}
          className={getClassName('manager_email_1', record)}
          schema={inputEmailSchema}
        />
      ),
    },
    {
      title: 'HRBP',
      dataIndex: 'hrbp_full_name',
      width: 250,
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'hrbp_full_name')}
          className={getClassName('hrbp_full_name', record)}
        />
      ),
    },
    {
      title: 'Matricule HRBP',
      dataIndex: 'hrbp_matricule',
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'hrbp_matricule')}
          className={getClassName('hrbp_matricule', record)}
          inputType="number"
          schema={inputNumberSchema}
        />
      ),
    },
    {
      title: 'Email HRBP',
      dataIndex: 'hrbp_email',
      width: 250,
      render: (text: any, record: any, index: any) => (
        <EditableTextCell
          key={index}
          text={text || ''}
          change={(value: any) => handleChange(value, record, 'hrbp_email')}
          className={getClassName('hrbp_email', record)}
          schema={inputEmailSchema}
        />
      ),
    },
    {
      title: 'Date neutralisation',
      dataIndex: 'on_hold_date',
      render: (text: any, record: any, index: any) =>
        text ? (
          <EditableDateCell
            key={index}
            value={text}
            change={(value: any) => handleChange(value, record, 'on_hold_date')}
            className={getClassName('on_hold_date', record)}
          />
        ) : (
          { text }
        ),
    },
    {
      title: 'Raison neutralisation',
      dataIndex: 'hold_reason',
      render: (text: any, record: any, index: any) =>
        text ? (
          <EditableTextCell
            key={index}
            text={text || ''}
            change={(value: any) => handleChange(value, record, 'hold_reason')}
            className={getClassName('hold_reason', record)}
          />
        ) : (
          { text }
        ),
    },
    {
      title: 'Pays',
      dataIndex: 'country',
      render: (text: any, record: any, index: any) =>
        text ? (
          <EditableTextCell
            key={index}
            text={text || ''}
            change={(value: any) => handleChange(value, record, 'country')}
            className={getClassName('country', record)}
          />
        ) : (
          { text }
        ),
    },
    {
      title: 'En process',
      dataIndex: 'is_in_process',
      align: 'center',
      render: (text: any) => <FontAwesomeIcon icon={formatTableData(text, 'is_in_process')} />,
    },
    {
      title: '%',
      dataIndex: 'mpp_percentage',
      fixed: 'right',
      align: 'center',
    },
  ];

  useEffect(() => {
    CollaboratorsService.fetchCollaborators(dispatch);
    ProcessesService.fetchProcesses(dispatch)
      .then((result: any) => {
        const copyProcesses: any = [...result.payload.processes];
        setProcesses(copyProcesses);
      })
      .catch((err: any) => {
        addToastr(
          'Récupération des Processus',
          'Une erreur est survenue lors de la récupération des processus, merci de réssayer ultérieurement.',
          'ERROR'
        );
        return err;
      });
  }, []);

  useEffect(() => {
    if (parameters.length < 1) ParametersService.fetchParameters(dispatch);
  }, [parameters]);

  useEffect(() => {
    let errorCollabs = [];
    let warningCollabs = [];
    let generalCollabs = [];

    if (collaborators && collaborators.length > 0) {
      generalCollabs = collaborators;
      errorCollabs = collaborators.filter((c: any) => c.hasErrors);
      warningCollabs = collaborators.filter((c: any) => c.hasWarnings);
    }

    setCollabsLists({
      general: generalCollabs,
      error: errorCollabs,
      warning: warningCollabs,
    });
    setNumberOfCollabs({
      general: generalCollabs.length,
      error: errorCollabs.length,
      warning: warningCollabs.length,
    });
  }, [collaborators]);

  const onFilter = (type: string, number: number) => {
    switch (type) {
      case 'general':
        setNumberOfCollabs({ ...numberOfCollabs, general: number });
        break;
      case 'error':
        setNumberOfCollabs({ ...numberOfCollabs, error: number });
        break;
      case 'warning':
        setNumberOfCollabs({ ...numberOfCollabs, warning: number });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Popover
        content={
          <TutorialPopover
            tutorial={
              parameters && parameters.length > 0 && parameters.find((p: any) => p.key === 'tutorial')
                ? parameters.find((p: any) => p.key === 'tutorial').value[0]
                : ''
            }
          />
        }
        title="Comment utiliser MPP Dashboard ?"
        placement="rightBottom"
        overlayStyle={{ color: '#0068A4' }}
      >
        <FontAwesomeIcon icon="info-circle" size="2x" className="tutorial-popover" />
      </Popover>
      <Tabs defaultActiveKey="1" type="card" centered>
        <TabPane
          tab={
            <div className="tabs">
              <span className="tabs_title" style={{ marginRight: '10px' }}>
                <FontAwesomeIcon icon="user-check" />
                Tableau général
              </span>
              <Badge count={numberOfCollabs.general} style={{ backgroundColor: '#0068A4' }} />
            </div>
          }
          key="1"
        >
          <Suspense fallback={<Spin />}>
            <GeneralTable
              columns={columns}
              processes={processes}
              parameters={parameters}
              collaborators={collabsLists.general}
              onFilter={(type: string, value: number) => onFilter(type, value)}
            />
          </Suspense>
        </TabPane>
        <TabPane
          tab={
            <div className="tabs">
              <span className="tabs_title" style={{ marginRight: '10px' }}>
                <FontAwesomeIcon icon="user-check" />
                Gestion des warnings
              </span>
              <Badge count={numberOfCollabs.warning} style={{ backgroundColor: '#0068A4' }} />
            </div>
          }
          key="2"
        >
          <Suspense fallback={<Spin />}>
            <WarningTable
              columns={columns}
              processes={processes}
              parameters={parameters}
              collaborators={collabsLists.warning}
              onFilter={(type: string, value: number) => onFilter(type, value)}
            />
          </Suspense>
        </TabPane>
        <TabPane
          tab={
            <div className="tabs">
              <span className="tabs_title" style={{ marginRight: '10px' }}>
                <FontAwesomeIcon icon="user-check" />
                Gestions des erreurs
              </span>
              <Badge count={numberOfCollabs.error} style={{ backgroundColor: '#0068A4' }} />
            </div>
          }
          key="3"
        >
          <Suspense fallback={<Spin />}>
            <ErrorTable
              columns={columns}
              processes={processes}
              parameters={parameters}
              collaborators={collabsLists.error}
              onFilter={(type: string, value: number) => onFilter(type, value)}
            />
          </Suspense>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Home;
