import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import getLongest from '../../../_utils/getLongest';
import './cell.scss';

const EditableSelectCell = ({ text, change, content, contentKey, className, additionalContent, disabled }: any) => {
  const [selectedContent, setSelectedContent] = useState('');

  const handler = useCallback(
    debounce((val: any) => change(val), 5000),
    []
  );

  const handleClick = (event: any) => {
    if (typeof event === 'object') {
      setSelectedContent(`${additionalContent}${event[contentKey]}`);
    } else {
      setSelectedContent(event);
    }
    handler(event);
  };

  const longest = getLongest(content, contentKey, additionalContent);

  const menuContent = content.map((c: any) => {
    if (typeof c === 'object') {
      return <Menu.Item onClick={() => handleClick(c)}>{`${additionalContent}${c[contentKey]}`}</Menu.Item>;
    }
    return <Menu.Item onClick={() => handleClick(c)}>{c}</Menu.Item>;
  });

  const menu = (
    <Menu>
      <Menu.Item key="any" onClick={() => setSelectedContent('')}>
        ...
      </Menu.Item>
      {menuContent}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} disabled={disabled}>
      <Button className={`btn dropdown ${className}`} style={{ width: `calc(${longest.length}ch + 50px)` }}>
        {selectedContent || text}
        <FontAwesomeIcon icon="chevron-down" className="icon" />
      </Button>
    </Dropdown>
  );
};

EditableSelectCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.any.isRequired,
  change: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.any).isRequired,
  contentKey: PropTypes.string,
  className: PropTypes.string,
  additionalContent: PropTypes.string,
  disabled: PropTypes.bool,
};

EditableSelectCell.defaultProps = {
  contentKey: '',
  className: '',
  additionalContent: '',
  disabled: false,
};

export default EditableSelectCell;
